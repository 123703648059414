import { API } from 'aws-amplify';
import {
  BrowseProjectEventContract,
  ProjectEvent,
  ProjectEventProperty,
  ProjectEventPropertyLite,
} from '../contracts/event.contract';

export default class ProjectEventsPropertiesRepository {
  private static api = process.env.REACT_APP_BACKEND_NAME;

  public static async edit(
    projectId: string,
    eventProperty: ProjectEventPropertyLite,
  ): Promise<BrowseProjectEventContract> {
    return await API.put(
      this.api,
      `/projects/${projectId}/events/${eventProperty.entity}/${eventProperty.action}/property/${eventProperty.property}`,
      { body: eventProperty },
    );
  }

  public static async add(
    projectId: string,
    eventProperty: ProjectEventPropertyLite,
  ): Promise<BrowseProjectEventContract> {
    return await API.post(
      this.api,
      `/projects/${projectId}/events/${eventProperty.entity}/${eventProperty.action}/property/${eventProperty.property}`,
      { body: eventProperty },
    );
  }

  public static async delete(
    projectId: string,
    eventProperty: ProjectEventProperty,
  ): Promise<BrowseProjectEventContract> {
    return await API.del(
      this.api,
      `/projects/${projectId}/events/${eventProperty.entity}/${eventProperty.action}/property/${eventProperty.property}`,
      {},
    );
  }
}

export function projectEventsMapToList(
  events: BrowseProjectEventContract,
): ProjectEvent[] {
  const list: ProjectEvent[] = [];

  Object.entries(events)
    .sort()
    .map(([entity, event]) => {
      return Object.entries(event)
        .sort()
        .map(([action, settings]) => {
          list.push({ entity, action, ...settings });
        });
    });

  return list;
}
