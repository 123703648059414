import { API } from 'aws-amplify';
import StatsRequestContract from '../../contracts/stats/stats.request.contract';
import TimeSeriesContract from '../../contracts/stats/time-series/time-series.contract';

export default class TimeSeriesRepository {
  private static api = process.env.REACT_APP_BACKEND_NAME;

  public static async get(
    projectId: string,
    kpi: string,
    params: StatsRequestContract,
  ): Promise<TimeSeriesContract> {
    return await API.get(
      this.api,
      `/projects/${projectId}/stats/time-series/${kpi}`,
      { body: params },
    );
  }
}
