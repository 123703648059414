import * as React from 'react';
import Layout from '../../../core/components/templates/layout.component';
import SkeletonRectangle from '../../../core/components/atoms/skeletons/skeleton-rectangle.component';
import UserContract from '../../../user/service/contracts/user.contract';
import ProjectEventsRepository from '../../service/api/project-events.repository';
import {
  BrowseProjectEventContract,
  ProjectEvent,
  ProjectEventImplementationType,
  ProjectEventLite,
  ProjectEventProperty,
  ProjectEventPropertyLite,
} from '../../service/contracts/event.contract';
import { classNames } from '../../../core/service/css/class-names.service';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CodeIcon,
  DotsVerticalIcon,
  PencilAltIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';
import ButtonPrimary from '../../../core/components/atoms/buttons/button-primary.component';
import EventModal from '../organisms/modals/event-modal.component';
import EventPropertyModal from '../organisms/modals/event-property-modal.component';
import { useLayout } from '../../../core/context/layout.context';
import { Menu, Transition } from '@headlessui/react';
import SimpleModal from '../organisms/modals/simple-modal.component';
import ProjectEventsPropertiesRepository, {
  projectEventsMapToList,
} from '../../service/api/project-events-properties.repository';
import { DocumentAddIcon } from '@heroicons/react/outline';
import Badge from '../../../core/components/atoms/badges/badges.component';
import {
  BrowseProjectEventsRecommendationContract,
  ProjectEventRecommendation,
} from '../../service/contracts/recommendation-event.contract';
import ProjectEventsRecommendationRepository from '../../service/api/project-recommendations.repository';
import EventCodeModal from '../organisms/modals/event-code-modal.component';

export interface ProjectEventsProps {
  currentUser: UserContract;
}

const ProjectEvents: React.FC<ProjectEventsProps> = (props) => {
  const {
    handleError,
    handleSuccess,
    handleProjectId,
    projectEvents,
    changeEvents,
  } = useLayout();
  const { projectId } = useParams<{ projectId: string }>();
  const [loadingEvents, setLoadingEvents] = React.useState(true);
  const [eventRecommendations, changeEventRecommendations] =
    React.useState<BrowseProjectEventsRecommendationContract>({});
  const [detailsShown, setDetailsShown] = React.useState([] as string[]);
  const [loadingAddEvent, setLoadingAddEvent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showEventModal, setShowEventModal] = React.useState<
    ProjectEvent | boolean
  >(false);
  const [confirmAddEventRecommendation, changeAddEventRecommendation] =
    React.useState<ProjectEventRecommendation | false>(false);
  const [showPropertyModal, setShowPropertyModal] = React.useState<
    ProjectEventProperty | false
  >(false);
  const [confirmDeleteEvent, changeDeleteEvent] = React.useState<
    ProjectEvent | false
  >(false);
  const [confirmDeleteProperty, changeDeleteProperty] = React.useState<
    ProjectEventProperty | false
  >(false);
  const [showPrecisionTaggingModal, setShowPrecisionTaggingModal] =
    React.useState<ProjectEvent | false>(false);

  React.useEffect(() => {
    if (!projectEvents) return setLoadingEvents(true);

    changeEvents(projectEvents);
    setLoadingEvents(false);
  }, [projectEvents]);

  React.useEffect(() => {
    handleProjectId(projectId);

    // weird useEffect ft. async pattern...
    async function init() {
      await Promise.all([loadEventsRecommendations()]);
    }
    init();
  }, []);

  const loadEventsRecommendations = async () => {
    await ProjectEventsRecommendationRepository.browse(projectId).then(
      (response) => {
        changeEventRecommendations(response);
      },
    );
  };

  const editEvent = async (event: ProjectEventLite) => {
    handleError('');
    setLoading(true);
    await ProjectEventsRepository.edit(projectId, event)
      .then((response) => {
        changeEvents(response);
        handleSuccess('Event updated');
      })
      .catch(() => {
        handleError('Failed to update the event');
      })
      .finally(() => {
        changeDeleteEvent(false);
        setLoading(false);
      });
  };

  const addEvent = async (event: ProjectEventLite) => {
    handleError('');
    setLoadingAddEvent(true);
    await ProjectEventsRepository.add(projectId, event)
      .then((response) => {
        changeEvents(response);
        handleSuccess('Event added');
      })
      .catch(() => {
        handleError('Failed to add the event');
      })
      .finally(() => {
        changeDeleteEvent(false);
        setLoadingAddEvent(false);
      });
  };

  const addEventRecommendation = async (
    recommendation: ProjectEventRecommendation,
  ) => {
    handleError('');
    setLoading(true);
    await ProjectEventsRecommendationRepository.add(projectId, recommendation)
      .then((response) => {
        changeEvents(response);
        handleSuccess('Recommendation added');

        // remove recommendation from list manually
        const newRecommendations = eventRecommendations;
        if (
          newRecommendations[recommendation.entity] &&
          newRecommendations[recommendation.entity][recommendation.action]
        ) {
          delete newRecommendations[recommendation.entity][
            recommendation.action
          ];

          // be careful and delete entities without actions
          if (!Object.entries(newRecommendations[recommendation.entity]).length)
            delete newRecommendations[recommendation.entity];

          changeEventRecommendations(newRecommendations);
        }
      })
      .catch(() => {
        handleError('Failed to add the event');
      })
      .finally(() => {
        changeAddEventRecommendation(false);
        setLoading(false);
      });
  };

  const editEventProperty = async (event: ProjectEventPropertyLite) => {
    handleError('');
    setLoading(true);
    await ProjectEventsPropertiesRepository.edit(projectId, event)
      .then((response) => {
        changeEvents(response);
        handleSuccess('Event property updated');
      })
      .catch(() => {
        handleError('Failed to update the event property');
      })
      .finally(() => {
        changeDeleteEvent(false);
        setLoading(false);
      });
  };

  const addEventProperty = async (event: ProjectEventPropertyLite) => {
    handleError('');
    setLoading(true);
    await ProjectEventsPropertiesRepository.add(projectId, event)
      .then((response) => {
        changeEvents(response);
        handleSuccess('Event property added');
      })
      .catch(() => {
        handleError('Failed to add the event property');
      })
      .finally(() => {
        changeDeleteEvent(false);
        setLoading(false);
      });
  };

  const deleteEvent = async (event: ProjectEvent) => {
    handleError('');
    setLoading(true);
    await ProjectEventsRepository.delete(projectId, event)
      .then((response) => {
        changeEvents(response);
        handleSuccess('Event deleted');
      })
      .catch(() => {
        handleError('Failed to delete event');
      })
      .finally(() => {
        changeDeleteEvent(false);
        setLoading(false);
      });
  };

  const deleteProperty = async (property: ProjectEventProperty) => {
    handleError('');
    setLoading(true);
    await ProjectEventsPropertiesRepository.delete(projectId, property)
      .then((response) => {
        changeEvents(response);
        handleSuccess('Property deleted');
      })
      .catch(() => {
        handleError('Failed to delete property');
      })
      .finally(() => {
        changeDeleteProperty(false);
        setLoading(false);
      });
  };

  const renderEventsList = (events?: BrowseProjectEventContract) => {
    if (!events) return null;

    const rows = projectEventsMapToList(events);

    if (!rows.length)
      return (
        <tr>
          <td colSpan={7} className="px-6 py-20">
            <div className="text-center">
              <DocumentAddIcon className="mx-auto h-12 w-12 text-gray-400"></DocumentAddIcon>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No events
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new event.
              </p>
              <div className="mt-6">
                <ButtonPrimary
                  label="New event"
                  loading={loadingAddEvent}
                  margin="ml-3"
                  className="h-10.5"
                  onClick={() => setShowEventModal(true)}
                  icon={renderPlusIcon()}
                />
              </div>
            </div>
          </td>
        </tr>
      );

    return rows.map((event) => {
      return renderEventsDetail(event);
    });
  };

  const renderEventsDetail = (event: ProjectEvent) => {
    const timestamp = event.updatedAt || event.createdAt;
    const lastEdited = timestamp
      ? new Date(timestamp).toLocaleString('en-US')
      : 'at the time';

    return (
      <React.Fragment key={event.entity + event.action}>
        <tr>
          <td
            onClick={() => toggleShown(event.entity, event.action)}
            className="px-6 py-4 text-sm font-medium text-gray-900 rounded-bl-lg truncate"
          >
            {event.entity}
          </td>
          <td
            onClick={() => toggleShown(event.entity, event.action)}
            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 truncate"
          >
            {event.action}
          </td>
          <td
            onClick={() => toggleShown(event.entity, event.action)}
            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
          >
            {renderPropertiesCount(event)}
          </td>
          <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
            {renderAddPropertyButton(event)}
          </td>
          <td
            onClick={() => toggleShown(event.entity, event.action)}
            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
          >
            {lastEdited}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {renderEventImplementation(event)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 rounded-br-lg">
            {renderEditMenu(event)}
          </td>
        </tr>
        {showDetails(event) && renderPropertiesList(event)}
      </React.Fragment>
    );
  };

  const renderEventImplementation = (event: ProjectEvent) => {
    const implementation =
      event.implementation || ProjectEventImplementationType.Precision;
    let title = 'Precision';
    let color = 'gray';

    switch (implementation) {
      case ProjectEventImplementationType.Auto:
        title = 'Auto';
        color = 'blue';
        break;
      case ProjectEventImplementationType.Precision:
        title = 'Precision';
        color = 'gray';
        break;
      case ProjectEventImplementationType.Remote:
        title = 'Remote';
        color = 'yellow';
        break;

      default:
        break;
    }
    return (
      <div className="flex justify-between">
        <Badge title={title} color={color}></Badge>
        {implementation == ProjectEventImplementationType.Precision && (
          <button
            onClick={() => setShowPrecisionTaggingModal(event)}
            className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <CodeIcon className={'h-5 w-5'} />
          </button>
        )}
      </div>
    );
  };

  const renderPropertiesList = (event: ProjectEvent) => {
    return (
      <React.Fragment>
        <tr className="bg-gray-50">
          <td
            colSpan={2}
            className={` pl-10 pr-6 text-sm font-medium text-gray-900`}
          >
            <p className="break-all mt-5 mb-5 font-bold">Property</p>
          </td>
          <td colSpan={2} className="px-6 text-sm text-gray-500">
            <p className="break-all mt-5 mb-5 font-bold">Type</p>
          </td>
          <td colSpan={2} className="px-6 text-sm text-gray-500">
            <p className="break-all mt-5 mb-5 font-bold">Example Value</p>
          </td>
          <td className={`px-6 whitespace-nowrap text-sm text-gray-500`}></td>
        </tr>
        {renderPropertiesDetail(event)}
      </React.Fragment>
    );
  };

  const renderPropertiesDetail = (event: ProjectEvent) => {
    const rows = Object.entries(event.properties);

    if (!rows.length)
      return (
        <tr>
          <td colSpan={7} className="px-6 py-20">
            <div className="text-center">
              <DocumentAddIcon className="mx-auto h-12 w-12 text-gray-400"></DocumentAddIcon>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No properties
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new property.
              </p>
              <div className="mt-6">
                <ButtonPrimary
                  label="Add property"
                  loading={loadingAddEvent}
                  margin="ml-3"
                  className="h-10.5"
                  onClick={() => setShowPropertyModal(event)}
                  icon={renderPlusIcon()}
                />
              </div>
            </div>
          </td>
        </tr>
      );

    return rows.sort().map(([name, property]) => {
      return (
        <tr key={name} className="bg-gray-50">
          <td
            colSpan={2}
            className="pl-10 pr-6 text-sm font-medium text-gray-900 rounded-bl-lg"
          >
            <p className="break-all mt-5 mb-5">{name}</p>
          </td>
          <td colSpan={2} className="px-6 text-sm text-gray-500">
            <p className="break-all mt-5 mb-5">{property.type}</p>
          </td>
          <td colSpan={2} className="px-6 text-sm text-gray-500 rounded-br-lg">
            <p className="break-all mt-5 mb-5">{property.example}</p>
          </td>
          <td className="px-6 text-sm text-gray-500 rounded-br-lg">
            {renderEditMenu(event, name)}
          </td>
        </tr>
      );
    });
  };

  const renderPropertiesCount = (event: ProjectEvent) => {
    return (
      <div className="relative flex items-center space-x-3">
        <span className="block">
          <h2 className="text-sm font-medium border-2 border-dotted p-1">
            <span className="absolute inset-0" aria-hidden="true" />
            {Object.keys(event.properties).length}
          </h2>
        </span>

        {showDetails(event) ? (
          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </div>
    );
  };

  const renderAddPropertyButton = (event: ProjectEvent) => {
    return (
      <button
        type="button"
        onClick={() => setShowPropertyModal(event)}
        className="inline-flex justify-center items-center py-2 text-sm font-medium rounded-md text-elbwalker bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-elbwalker px-6 hover:bg-gray-50"
      >
        <PlusIcon className={`h-4.5 w-4.5 align-middle items-center mr-2`} />
        Add property
      </button>
    );
  };

  const renderEditMenu = (event: ProjectEvent, property?: string) => {
    return (
      <div className="block px-4 py-2 text-sm outline-none focus:outline-none">
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-elbwalker">
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-50 overflow-hidden origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-gray-300 ring-opacity-5 focus:outline-none divide-y divide-gray-200"
                >
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          property
                            ? setShowPropertyModal({
                                ...event,
                                property,
                              })
                            : setShowEventModal(event)
                        }
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'group flex items-center px-4 py-3 text-sm w-full text-left outline-none focus:outline-none',
                        )}
                      >
                        <PencilAltIcon
                          className="h-5 w-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          property
                            ? changeDeleteProperty({
                                ...event,
                                property,
                              })
                            : changeDeleteEvent(event)
                        }
                        className={classNames(
                          active ? 'bg-gray-100 text-red-500' : 'text-red-400',
                          'group flex items-center px-4 py-3 text-sm w-full text-left outline-none focus:outline-none',
                        )}
                      >
                        <TrashIcon
                          className="h-5 w-5 mr-3 text-red-400 group-hover:text-red-500"
                          aria-hidden="true"
                        />
                        Delete
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  };

  const showDetails = (event: ProjectEvent) => {
    return detailsShown.includes(event.entity + event.action);
  };

  const toggleShown = (entity: string, action: string) => {
    const rowIdentifier = entity + action;
    const shownState = detailsShown.slice();
    const index = shownState.indexOf(rowIdentifier);

    if (index >= 0) {
      shownState.splice(index, 1);
      setDetailsShown(shownState);
    } else {
      shownState.push(rowIdentifier);
      setDetailsShown(shownState);
    }
  };

  const renderPlusIcon = () => {
    return (
      <PlusIcon className={'h-4.5 w-4.5 align-middle items-center mr-2'} />
    );
  };

  const renderEventModal = () => {
    if (!projectEvents || !showEventModal) return undefined;

    // check if an event is set to edit or just true when adding new one
    const event = showEventModal === true ? undefined : showEventModal;

    return (
      <EventModal
        buttonPrimaryOnClick={(event: ProjectEventLite, isEdit: boolean) => {
          isEdit ? editEvent(event) : addEvent(event);
          setShowEventModal(false);
        }}
        buttonSecondaryOnClick={() => setShowEventModal(false)}
        loading={false}
        event={event}
        events={projectEvents}
        values={Object.keys(projectEvents)}
      />
    );
  };

  const renderEventRecommendationModal = () => {
    if (!confirmAddEventRecommendation) return undefined;

    return (
      <SimpleModal
        title={`${confirmAddEventRecommendation.entity} ${confirmAddEventRecommendation.action}`}
        text={confirmAddEventRecommendation.text}
        ButtonPrimaryLabel="Add event"
        ButtonSecondaryLabel="Cancel"
        loading={loading}
        icon={'DocumentAdd'}
        red={false}
        ButtonPrimaryOnClick={() => {
          addEventRecommendation(confirmAddEventRecommendation);
        }}
        ButtonSecondaryOnClick={() => {
          changeAddEventRecommendation(false);
        }}
      ></SimpleModal>
    );
  };

  const renderPropertyModal = () => {
    if (!showPropertyModal) return undefined;

    return (
      <EventPropertyModal
        buttonPrimaryOnClick={(
          event: ProjectEventPropertyLite,
          isEdit: boolean,
        ) => {
          isEdit ? editEventProperty(event) : addEventProperty(event);
          setShowPropertyModal(false);
        }}
        buttonSecondaryOnClick={() => setShowPropertyModal(false)}
        loading={false}
        eventProperty={showPropertyModal}
      />
    );
  };

  const renderRemoveEventModal = () => {
    if (!confirmDeleteEvent) return undefined;

    return (
      <SimpleModal
        title="Delete event"
        text={`Are you sure you want to delete the event ${confirmDeleteEvent.entity} ${confirmDeleteEvent.action}?`}
        ButtonPrimaryLabel="Delete"
        red={true}
        ButtonSecondaryLabel="Cancel"
        loading={loading}
        ButtonPrimaryOnClick={() => {
          deleteEvent(confirmDeleteEvent);
        }}
        ButtonSecondaryOnClick={() => {
          changeDeleteEvent(false);
        }}
      ></SimpleModal>
    );
  };

  const renderRemovePropertyModal = () => {
    if (!confirmDeleteProperty) return undefined;

    return (
      <SimpleModal
        title="Delete property"
        text={`Are you sure you want to delete the ${confirmDeleteProperty.property} property from the ${confirmDeleteProperty.entity} ${confirmDeleteProperty.action} event?`}
        ButtonPrimaryLabel="Delete"
        red={true}
        ButtonSecondaryLabel="Cancel"
        loading={loading}
        ButtonPrimaryOnClick={() => {
          deleteProperty(confirmDeleteProperty);
        }}
        ButtonSecondaryOnClick={() => {
          changeDeleteProperty(false);
        }}
      ></SimpleModal>
    );
  };

  const renderPrecisionTaggingModal = () => {
    if (!showPrecisionTaggingModal) return undefined;

    return (
      <EventCodeModal
        event={showPrecisionTaggingModal}
        onCloseClick={() => {
          setShowPrecisionTaggingModal(false);
        }}
      ></EventCodeModal>
    );
  };

  const renderEventRecommendationsList = (
    events: BrowseProjectEventsRecommendationContract,
  ) => {
    const recommendations = Object.entries(events);

    if (!recommendations.length) {
      return;
    }

    return (
      <div className="mt-24 px-10 text-xs font-medium text-gray-500 uppercase tracking-wider">
        Recommended Events for you
        <ul
          role="list"
          className="grid grid-cols-1 gap-4 sm:grid-cols-3 mt-3 rounded-lg"
        >
          {recommendations.sort().map(([entity, events]) => {
            return Object.entries(events)
              .sort()
              .map(([action, settings], i) => {
                return renderEventRecommendationsDetail(
                  {
                    entity,
                    action,
                    ...settings,
                  },
                  i,
                );
              });
          })}
        </ul>
      </div>
    );
  };

  const renderEventRecommendationsDetail = (
    recommendation: ProjectEventRecommendation,
    i: number,
  ) => {
    return (
      <li key={i}>
        <button
          type="button"
          onClick={() => changeAddEventRecommendation(recommendation)}
          className="group p-2 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-elbwalker bg-white"
        >
          <span className="min-w-0 flex-1 flex items-center space-x-3 pl-3">
            <span className="block min-w-0 flex-1">
              <span className="block text-sm font-medium text-gray-900 truncate">
                {recommendation.entity} {recommendation.action}
              </span>
              <span className="block text-sm font-medium text-gray-500 truncate">
                {recommendation.text}
              </span>
            </span>
          </span>
          <span className="flex-shrink-0 h-10 w-10 inline-flex items-center justify-center">
            <PlusIcon
              className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </button>
      </li>
    );
  };

  const content = (
    <div>
      {renderEventModal()}
      {renderEventRecommendationModal()}
      {renderPropertyModal()}
      {renderRemoveEventModal()}
      {renderRemovePropertyModal()}
      {renderPrecisionTaggingModal()}
      <div className="flex flex-row-reverse mb-3">
        <ButtonPrimary
          label="New event"
          loading={loadingAddEvent}
          margin="ml-3"
          className="h-10.5"
          onClick={() => setShowEventModal(true)}
          icon={renderPlusIcon()}
        />
      </div>
      <div className="flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow border-b border-gray-200 sm:rounded-lg">
              <table className="rounded-lg bg-white table-fixed w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="w-3/20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider rounded-tl-lg">
                      Entity
                    </th>
                    <th className="w-3/20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Action
                    </th>
                    <th className="w-11/100 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Properties
                    </th>
                    <th className="w-17/100 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                    <th className="w-18/100 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last edited
                    </th>
                    <th className="w-3/20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Implementation
                    </th>
                    <th className="w-9/100 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider rounded-tr-lg"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loadingEvents ? (
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-900 rounded-bl-lg">
                        <SkeletonRectangle width="full" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="full" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="full" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="full" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <SkeletonRectangle width="full" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 rounded-br-lg">
                        <SkeletonRectangle width="full" />
                      </td>
                    </tr>
                  ) : (
                    renderEventsList(projectEvents)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {renderEventRecommendationsList(eventRecommendations)}
    </div>
  );

  return (
    <Layout title="Events" currentUser={props.currentUser}>
      {content}
    </Layout>
  );
};

ProjectEvents.defaultProps = {};

export default ProjectEvents;
