import { useContext, useState, useEffect, createContext } from 'react';
import * as React from 'react';
import { Auth } from 'aws-amplify';
import { ProjectContract } from '../../project/service/contracts/project.contract';
import { CognitoUser } from '@aws-amplify/auth';
import UserContract from '../../user/service/contracts/user.contract';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../routes';

const AuthContext = createContext<AuthContextProps | null>(null);

export interface AuthContextProps {
  authorizing: boolean;
  currentUser: UserContract | null;
  handleCurrentProject: (project?: ProjectContract) => null | ProjectContract;
  signup: (email: string, password: string, name: string) => Promise<void>;
  resendVerification: (username: string) => Promise<boolean>;
  verifyVerification: (username: string, code: string) => Promise<boolean>;
  login: (
    email: string,
    password: string,
    remember: boolean,
  ) => Promise<CognitoUser>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  // updateEmail;
  // updatePassword;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error('`useAuth` must be within a `AuthProvider`');

  return context;
}

interface IProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: IProps) {
  const history = useHistory();
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [currentUser, setCurrentUser] = useState<UserContract | null>(null);
  const [currentProject, setCurrentProject] = useState<ProjectContract | null>(
    null,
  );
  const [authorizing, setAuthorizing] = useState(true);

  useEffect(() => {
    getUser()
      .then(async (cognitoUser) => {
        if (!cognitoUser) {
          setAuthorizing(false);
          history.push(Paths.login);
          return;
        }
      })
      .catch(() => {
        // The user is not authenticated
      });
  }, []);

  async function getUser(): Promise<CognitoUser | null> {
    setAuthorizing(true);
    let user: CognitoUser | null = null;

    try {
      user = (await Auth.currentAuthenticatedUser()) as CognitoUser;

      setCognitoUser(cognitoUser);

      const { attributes } = await Auth.currentAuthenticatedUser();

      const foo: UserContract = {
        id: attributes.sub || '',
        email: attributes.email || '',
        name: attributes.name || '',
        plan: 1, // @TODO
      };
      setCurrentUser(foo);
    } catch (error) {
      throw error;
    } finally {
      setAuthorizing(false);
    }

    return user;
  }

  async function signup(username: string, password: string, name: string) {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          name,
        },
      });

      return;
    } catch (error) {
      throw error;
    }
  }

  async function resendVerification(username: string) {
    try {
      await Auth.resendSignUp(username);
      return true;
    } catch (error) {
      throw error;
    }
  }

  async function verifyVerification(username: string, code: string) {
    try {
      await Auth.confirmSignUp(username, code);
      return true;
    } catch (error) {
      throw error;
    }
  }

  async function login(username: string, password: string, remember: boolean) {
    try {
      await Auth.signIn(username, password);
      const user = await getUser();

      if (!user) throw 'signIn error';

      return user;

      // if (!remember)
      //   user.setDeviceStatusNotRemembered({
      //     onSuccess: function () {
      //       remember = false;
      //     },
      //     onFailure: (error) => {
      //       throw error;
      //     },
      //   });
    } catch (error) {
      throw error;
    }
  }

  async function logout() {
    try {
      await Auth.signOut();
    } catch (error) {
      throw error;
    }
  }

  async function resetPassword() {
    return;
  }

  function handleCurrentProject(project?: ProjectContract) {
    if (project) setCurrentProject(project);
    return currentProject;
  }

  const value = {
    authorizing,
    currentUser,
    handleCurrentProject,
    signup,
    resendVerification,
    verifyVerification,
    login,
    logout,
    resetPassword,
    // updateEmail,
    // updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!authorizing && children}
    </AuthContext.Provider>
  );
}
