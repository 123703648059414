import { API } from 'aws-amplify';
import { BrowseProjectMembersContract } from '../contracts/project.contract';

export default class ProjectMembersRepository {
  private static api = process.env.REACT_APP_BACKEND_NAME;

  public static async browse(
    projectId: string,
  ): Promise<BrowseProjectMembersContract> {
    return await API.get(this.api, `/projects/${projectId}/members`, {});
  }

  public static async add(
    projectId: string,
    email: string,
  ): Promise<BrowseProjectMembersContract> {
    return await API.post(this.api, `/projects/${projectId}/members`, {
      body: email,
    });
  }

  public static async delete(
    projectId: string,
    memberId: string,
  ): Promise<BrowseProjectMembersContract> {
    return await API.del(
      this.api,
      `/projects/${projectId}/members/${memberId}`,
      {},
    );
  }
}
