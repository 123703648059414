import { API } from 'aws-amplify';
import { BrowseProjectEventContract } from '../contracts/event.contract';
import {
  BrowseProjectEventsRecommendationContract,
  ProjectEventRecommendation,
} from '../contracts/recommendation-event.contract';

export default class ProjectEventsRecommendationRepository {
  private static api = process.env.REACT_APP_BACKEND_NAME;

  public static async browse(
    projectId: string,
  ): Promise<BrowseProjectEventsRecommendationContract> {
    return await API.get(
      this.api,
      `/projects/${projectId}/events/recommendation`,
      {},
    );
  }

  public static async add(
    projectId: string,
    recommendation: ProjectEventRecommendation,
  ): Promise<BrowseProjectEventContract> {
    return await API.post(
      this.api,
      `/projects/${projectId}/events/recommendation/${recommendation.entity}/${recommendation.action}`,
      {
        body: recommendation,
      },
    );
  }
}
