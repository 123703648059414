import { API } from 'aws-amplify';
import UserContract from '../contracts/user.contract';

export default class UserRepository {
  private static api = process.env.REACT_APP_BACKEND_NAME;

  public static async edit(
    userId: string,
    user: Partial<UserContract>,
  ): Promise<UserContract> {
    return await API.put(this.api, `/users/${userId}`, { body: user });
  }
}
