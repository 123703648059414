import { API } from 'aws-amplify';
import DimensionalFactsContract from '../../contracts/stats/dimensional-facts/dimensional-facts.contract';
import DimensionalFactsRequestParamsContract from '../../contracts/stats/dimensional-facts/dimensional-facts-request-params.contract';

export default class DimensionalFactsRepository {
  private static api = process.env.REACT_APP_BACKEND_NAME;

  public static async read(
    projectId: string,
    dimension: string,
    params: DimensionalFactsRequestParamsContract,
  ): Promise<DimensionalFactsContract> {
    return await API.get(
      this.api,
      `/projects/${projectId}/stats/dimensional-facts/${dimension}`,
      { body: params },
    );
  }
}
