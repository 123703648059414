// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/

import { Auth } from 'aws-amplify';

const amplify_config = {
  aws_project_region: process.env.REACT_APP_AMPLIFY_REGION,

  Auth: {
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID,
  },

  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_BACKEND_NAME,
        endpoint: process.env.REACT_APP_BACKEND_API,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
          // return {
          //   Authorization: `Bearer ${(await Auth.currentSession())
          //     .getIdToken()
          //     .getJwtToken()}`,
          // };
        },
      },
    ],
  },
};

export default amplify_config;
