import { useState } from 'react';
import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AuthContextProps, useAuth } from '../../context/auth.context';
import { Paths } from '../../../routes';
import SignupProcess from '../templates/auth-layout.component';
import FormLabeledInput from '../molecules/forms/form-labeled-input.component';
import ButtonPrimary from '../atoms/buttons/button-primary.component';
import { useLayout } from '../../context/layout.context';

export interface LoginProps {
  title?: string;
}

const Login: React.FC<LoginProps> = () => {
  const history = useHistory();
  const { login, resendVerification, verifyVerification } =
    useAuth() as AuthContextProps;
  const { handleError, handleSuccess } = useLayout();

  let { state } =
    useLocation<{ email: string; password: string; verify: boolean }>();
  state = state || {};

  const [loading, setLoading] = useState(false);
  const [email, changeEmail] = useState<string>(state.email || '');
  const [password, changePassword] = useState<string>(state.password || '');
  const [remember, changeRemember] = useState<boolean>(false);

  const [verify, changeVerify] = useState<boolean>(state.verify || false);
  const [code, changeCode] = useState<string>('');

  const handleKeyDown = async (e: any) => {
    if (e.keyCode === 13 || e?.key === 'Enter') {
      await handleLogIn();
    }
  };

  async function handleLogIn() {
    if (!email) return handleError('Email required');
    if (!password) return handleError('Password required');

    setLoading(true);

    if (verify) {
      await verifyVerification(email.trim(), code)
        .then(() => {
          changeVerify(false);
          handleSuccess('Successfully verified');
        })
        .catch(() => {
          handleError('Failed to verify');
        });
    } else {
      await login(email.trim(), password, remember)
        .then(() => {
          handleSuccess('Successfully logged in');
          history.push(Paths.home);
        })
        .catch((error) => {
          if (error.code && error.code === 'UserNotConfirmedException') {
            changeVerify(true);
            handleError('Please verify account');
          } else {
            handleError('Failed to log in');
          }
        });
    }

    setLoading(false);
  }

  async function sendVerificationCode() {
    handleError('');
    setLoading(true);

    try {
      await resendVerification(email);
      handleSuccess('Please check your inbox.');
    } catch {
      handleError('Failed to send verification code');
    } finally {
      setLoading(false);
    }
  }

  const renderContent = () => {
    return (
      <div>
        <div className="mb-6">
          <div className="relative">
            <div className="relative flex justify-center text-xl font-medium">
              <span className="px-2 bg-white text-gray-700">
                Welcome back! Please log in ...
              </span>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <FormLabeledInput
            id={'email'}
            type={'text'}
            title={'Email'}
            placeholder={'you@yourcompany.com'}
            value={email}
            required={true}
            onChange={(value) => changeEmail(value.trim())}
            onKeyDown={async (e: any) => await handleKeyDown(e)}
            loading={false}
          />
          {!verify && (
            <>
              <FormLabeledInput
                id={'password'}
                type={'password'}
                title={'Password'}
                value={password}
                required={true}
                onChange={(value) => changePassword(value)}
                onKeyDown={async (e: any) => await handleKeyDown(e)}
                loading={false}
              />
              <div className="grid grid-cols-14 gap-y-4">
                <div className="col-start-2 col-span-12">
                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center">
                      <input
                        id="remember"
                        name="remember"
                        type="checkbox"
                        checked={remember}
                        onChange={(event) =>
                          changeRemember(event.target.checked)
                        }
                        className="h-4 w-4 text-elbwalker focus:ring-elbwalker border-gray-300 rounded"
                      />
                      <label
                        htmlFor="remember"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>
                    <Link
                      to={Paths.password.reset}
                      className="ml-1 font-medium text-elbwalker hover:text-elbwalker-600 focus:outline-none focus:underline transition ease-in-out duration-150"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
          {verify && (
            <>
              <FormLabeledInput
                id={'code'}
                type={'text'}
                title={'Verification code'}
                value={code}
                placeholder={'#######'}
                required={true}
                onChange={(value) => changeCode(value.trim())}
                onKeyDown={async (e: any) => await handleKeyDown(e)}
                loading={false}
              />
              <p className="text-center text-sm text-gray-600 max-w">
                Didn&apos;t receive the code?{' '}
                <Link
                  to="#"
                  onClick={() => sendVerificationCode()}
                  className="font-medium text-elbwalker hover:text-elbwalker-600 underline"
                >
                  Resend verification
                </Link>
              </p>
            </>
          )}

          <ButtonPrimary
            label={verify ? 'Verify' : 'Log In'}
            loading={loading}
            disabled={loading}
            wide={true}
            onClick={async () => await handleLogIn()}
          />
        </div>

        <div className="absolute inset-x-0 bottom-0 mt-6 py-8">
          <p className="mt-2 text-center text-sm text-gray-600 max-w">
            Don&apos;t have an account yet?&nbsp;
            <Link
              to={Paths.signup}
              className="font-medium text-elbwalker hover:text-elbwalker-600 underline"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    );
  };

  return <SignupProcess content={renderContent()}></SignupProcess>;
};

Login.defaultProps = {};

export default Login;
