import { useState } from 'react';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContextProps, useAuth } from '../../context/auth.context';
import { Paths } from '../../../routes';
import SignupProcess from '../templates/auth-layout.component';
import ButtonPrimary from '../atoms/buttons/button-primary.component';
import FormLabeledInput from '../molecules/forms/form-labeled-input.component';
import { useLayout } from '../../context/layout.context';

export interface SignupProps {
  text?: string;
}

const Signup: React.FC<SignupProps> = () => {
  const history = useHistory();
  const { handleError } = useLayout();
  const [loading, setLoading] = useState(false);

  const { signup } = useAuth() as AuthContextProps;

  const [name, changeName] = useState<string>('');
  const [email, changeEmail] = useState<string>('');
  const [password, changePassword] = useState<string>('');
  const [confirmPassword, changeConfirmPassword] = useState<string>('');
  const [privacyPolicy, changePrivacyPolicy] = useState<string>('');
  const [terms, changeTerms] = useState<string>('');

  const handleKeyDown = async (e: any) => {
    if (e.keyCode === 13 || e?.key === 'Enter') {
      await handleSignUp();
    }
  };

  async function handleSignUp() {
    if (!name) return handleError('Full name required');
    if (!email) return handleError('Email required');
    if (!password) return handleError('Password required');
    // @TODO new password policy
    // MinimumLength: 8
    // RequireLowercase: true
    // RequireNumbers: true
    // RequireSymbols: true
    // RequireUppercase: true
    if (password.length < 6) return handleError('Password too short');
    if (!confirmPassword) return handleError('Confirm password required');
    if (password != confirmPassword)
      return handleError("The passwords don't match");
    if (!privacyPolicy || privacyPolicy == 'false')
      return handleError('You have to accept the privacy policy');
    if (!terms || terms == 'false')
      return handleError('You have to accept the terms of service');

    try {
      handleError('');
      setLoading(true);
      await signup(email, password, name);
      history.push(Paths.login, { email, password, verify: true });
    } catch (err) {
      handleError('Failed to sign up');
      setLoading(false);
    }
  }

  const renderContent = () => {
    return (
      <div>
        <div className="mb-6">
          <div className="relative">
            <div className="relative flex justify-center text-xl font-medium">
              <span className="px-2 bg-white text-gray-700">Hello.</span>
            </div>
            <div className="relative flex justify-center text-xl font-medium">
              <span className="px-2 bg-white text-gray-700">
                To get started please sign up ...
              </span>
            </div>
          </div>
        </div>
        <div className="space-y-6">
          <FormLabeledInput
            id={'user_fullname'}
            type={'text'}
            title={'Full name'}
            value={name}
            placeholder={'Your name'}
            autoComplete={'name'}
            required={true}
            onChange={(value) => changeName(value)}
            onKeyDown={async (e: any) => await handleKeyDown(e)}
            loading={false}
          />
          <FormLabeledInput
            id={'email'}
            type={'email'}
            title={'Email'}
            value={email}
            placeholder={'you@yourcompany.com'}
            autoComplete={'email'}
            required={true}
            onChange={(value) => changeEmail(value)}
            onKeyDown={async (e: any) => await handleKeyDown(e)}
            loading={false}
          />
          <FormLabeledInput
            id={'password'}
            type={'password'}
            title={'Password'}
            value={password}
            required={true}
            onChange={(value) => changePassword(value)}
            onKeyDown={async (e: any) => await handleKeyDown(e)}
            loading={false}
          />
          <FormLabeledInput
            id={'confirmPassword'}
            type={'password'}
            title={'Confirm password'}
            value={confirmPassword}
            required={true}
            onChange={(value) => changeConfirmPassword(value)}
            onKeyDown={async (e: any) => await handleKeyDown(e)}
            loading={false}
          />
          <div className="grid grid-cols-14 gap-y-4">
            <div className="col-start-2 col-span-12 flex items-center">
              <input
                id="privacyPolicy"
                name="privacyPolicy"
                type="checkbox"
                value={privacyPolicy}
                onChange={(e: any) => {
                  e.target.checked
                    ? changePrivacyPolicy('checked')
                    : changePrivacyPolicy('false');
                }}
                onKeyDown={async (e: any) => await handleKeyDown(e)}
                required
                className="h-4 w-4 text-elbwalker focus:ring-elbwalker border-gray-300 rounded"
              />
              <label
                htmlFor="privacyPolicy"
                className="ml-2 block text-sm text-gray-900"
              >
                I agree to elbwalker&apos;s&nbsp;
                <a href={Paths.privacy} className="underline">
                  privacy policy
                </a>
                {/*
                <Link to={Paths.privacy} className="underline">
                  privacy policy
                </Link>
                */}
              </label>
            </div>
            <div className="col-start-2 col-span-12 flex items-center">
              <input
                id="terms"
                name="terms"
                type="checkbox"
                value={terms}
                onChange={(e: any) => {
                  e.target.checked
                    ? changeTerms('checked')
                    : changeTerms('false');
                }}
                onKeyDown={async (e: any) => await handleKeyDown(e)}
                required
                className="h-4 w-4 text-elbwalker focus:ring-elbwalker border-gray-300 rounded"
              />
              <label
                htmlFor="terms"
                className="ml-2 block text-sm text-gray-900"
              >
                I agree to elbwalker&apos;s&nbsp;
                <a href={Paths.terms} className="underline">
                  terms of service
                </a>
                {/*
                <Link to={Paths.terms} className="underline">
                  terms of service
                </Link>
                */}
              </label>
            </div>
          </div>
          <div>
            <ButtonPrimary
              label="Create new account"
              disabled={loading}
              loading={loading}
              onClick={() => handleSignUp()}
              wide={true}
              textSize="text-base"
            ></ButtonPrimary>
          </div>
        </div>
        <div className="mt-6">
          <p className="mt-2 text-center text-sm text-gray-600 max-w">
            Already have an account?&nbsp;
            <Link
              to={Paths.login}
              className="font-medium text-elbwalker hover:text-elbwalker-600 underline"
            >
              Log in
            </Link>
          </p>
        </div>
      </div>
    );
  };

  return <SignupProcess content={renderContent()}></SignupProcess>;
};

Signup.defaultProps = {};

export default Signup;
