import { API } from 'aws-amplify';
import {
  ProjectContract,
  BrowseProjectContract,
  AddProjectContract,
  EditProjectContract,
} from '../contracts/project.contract';

export default class ProjectRepository {
  private static api = process.env.REACT_APP_BACKEND_NAME;

  public static async browse(): Promise<BrowseProjectContract> {
    return await API.get(this.api, `/projects`, {});
  }

  public static async read(projectId: string): Promise<ProjectContract> {
    return await API.get(this.api, `/projects/${projectId}`, {});
  }

  public static async edit(
    projectId: string,
    project: EditProjectContract,
  ): Promise<ProjectContract> {
    return await API.put(this.api, `/projects/${projectId}`, { body: project });
  }

  public static async add(
    project: AddProjectContract,
  ): Promise<ProjectContract> {
    return await API.post(this.api, `/projects`, { body: project });
  }

  public static async delete(
    projectId: string,
  ): Promise<BrowseProjectContract> {
    return await API.del(this.api, `/projects/${projectId}`, {});
  }
}
