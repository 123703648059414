import { API } from 'aws-amplify';
import {
  BrowseProjectEventContract,
  ProjectEvent,
  ProjectEventLite,
} from '../contracts/event.contract';

export default class ProjectEventsRepository {
  private static api = process.env.REACT_APP_BACKEND_NAME;

  public static async browse(
    projectId: string,
  ): Promise<BrowseProjectEventContract> {
    return await API.get(this.api, `/projects/${projectId}/events`, {});
  }

  public static async edit(
    projectId: string,
    event: ProjectEventLite,
  ): Promise<BrowseProjectEventContract> {
    return await API.put(
      this.api,
      `/projects/${projectId}/events/${event.entity}/${event.action}`,
      { body: event },
    );
  }

  public static async add(
    projectId: string,
    event: ProjectEventLite,
  ): Promise<BrowseProjectEventContract> {
    return await API.post(
      this.api,
      `/projects/${projectId}/events/${event.entity}/${event.action}`,
      { body: event },
    );
  }

  public static async delete(
    projectId: string,
    event: ProjectEvent,
  ): Promise<BrowseProjectEventContract> {
    return await API.del(
      this.api,
      `/projects/${projectId}/events/${event.entity}/${event.action}`,
      {},
    );
  }
}
